import Client from "../client/Client"
import version from "../version"
const resource = version + "/tenant/calendar/students"

function getAllSchedule(school_id, params) {
  return Client.get(`${version}/tenant/schools/${school_id}/schedules/all/`, { params })
}
function getAllStudentSchedule(school_id, params) {
  return Client.get(`${resource}/${school_id}/student-schedules/all/`, { params })
}
function getListStudentSchedule(student_id, params) {
  return Client.get(`${resource}/${student_id}/student-schedules/`, { params })
}
function updateSubject(school_id, student_schedule_id, data) {
  return Client.put(
    `${resource}/${school_id}/student-schedules/${student_schedule_id}/update-subject/`,
    data
  )
}
function updateSchool(school_id, student_schedule_id, data) {
  return Client.put(
    `${resource}/${school_id}/student-schedules/${student_schedule_id}/update-school/`,
    data
  )
}
function updateCancelSeat(school_id, student_schedule_id, data) {
  return Client.put(
    `${resource}/${school_id}/student-schedules/${student_schedule_id}/cancel-seat-allocation/`,
    data
  )
}
function updateTransfer(school_id, student_schedule_id, data) {
  return Client.put(
    `${resource}/${school_id}/student-schedules/${student_schedule_id}/transfer/`,
    data
  )
}
function updateCancelTransfer(school_id, student_schedule_id, data) {
  return Client.put(
    `${resource}/${school_id}/student-schedules/${student_schedule_id}/cancel-transfer/`,
    data
  )
}

function updateAbsence(school_id, student_schedule_id, data) {
  return Client.put(
    `${resource}/${school_id}/student-schedules/${student_schedule_id}/make-as-absence/`,
    data
  )
}

function updateCancelAbsence(school_id, student_schedule_id, data) {
  return Client.put(
    `${resource}/${school_id}/student-schedules/${student_schedule_id}/cancel-absence/`,
    data
  )
}
function updateActivate(school_id, student_schedule_id, data) {
  return Client.put(
    `${resource}/${school_id}/student-schedules/${student_schedule_id}/active/`,
    data
  )
}
function updateInactivate(school_id, student_schedule_id, data) {
  return Client.put(
    `${resource}/${school_id}/student-schedules/${student_schedule_id}/inactivate/`,
    data
  )
}
function updateMove(student_id, student_schedule_id, data) {
  return Client.put(
    `${resource}/${student_id}/student-schedules/${student_schedule_id}/update-schedule/`,
    data
  )
}
function getStudentContract(params) {
  return Client.get(`${version}/tenant/student/contract/`, { params })
}

function getStudentNotes(student_id, params) {
  return Client.get(`${version}/tenant/calendar/students/${student_id}/student-schedule-notes/`, {
    params
  })
}
function createStudentNotes(student_id, data) {
  return Client.post(
    `${version}/tenant/calendar/students/${student_id}/student-schedule-notes/`,
    data
  )
}
function updateStudentNotes(student_id, student_schedule_note_id, data) {
  return Client.put(
    `${version}/tenant/calendar/students/${student_id}/student-schedule-notes/${student_schedule_note_id}/`,
    data
  )
}
function removeStudentNotes(student_id, student_schedule_note_id) {
  return Client.delete(
    `${version}/tenant/calendar/students/${student_id}/student-schedule-notes/${student_schedule_note_id}/`
  )
}
function updateStudentNoteToSchedule(student_id, student_schedule_note_id, data) {
  return Client.put(
    `${version}/tenant/calendar/students/${student_id}/student-schedule-notes/${student_schedule_note_id}/update-schedule/`,
    data
  )
}

function bulkAssignment(student_id, data) {
  return Client.post(
    `${version}/tenant/calendar/students/${student_id}/student-schedules/bulk-allocation/`,
    data
  )
}
const api = {
  getAllSchedule,
  getAllStudentSchedule,
  getListStudentSchedule,
  updateSubject,
  updateSchool,
  updateCancelSeat,
  updateTransfer,
  updateCancelTransfer,
  updateAbsence,
  updateCancelAbsence,
  updateInactivate,
  updateActivate,
  updateMove,
  getStudentContract,
  getStudentNotes,
  createStudentNotes,
  updateStudentNotes,
  removeStudentNotes,
  updateStudentNoteToSchedule,
  bulkAssignment
}

export default api
