const key = {
  SET_AUTH: "SET_AUTH",
  LOG_OUT: "LOG_OUT",
  SET_PROJECT: "SET_PROJECT",
  SET_AREAS: "SET_AREAS",
  SET_SCHOOL_LEVEL: "SET_SCHOOL_LEVEL",
  SET_SCHOOL_YEAR: "SET_SCHOOL_YEAR",
  SET_LOCALE: "SET_LOCALE",
  SET_SCHOOL_YEAR_OBJECT: "SET_SCHOOL_YEAR_OBJECT",
  CALLING: "CALLING"
}

export default key
