import Client from "../client/Client"
import version from "../version"
const resource = version + "/tenant/calendar"

function getSeatingChartLayout(school_id, params) {
  return Client.get(`${resource}/schools/${school_id}/searting-chart/`, { params })
}
function getSeatingChartByRoom(school_id, params) {
  return Client.get(`${resource}/schools/${school_id}/searting-chart/group-by-room/`, { params })
}
function get(id) {
  return Client.get(`${resource}/${id}`)
}
function create(data) {
  return Client.post(`${resource}/`, data)
}
function update(id, data) {
  return Client.put(`${resource}/${id}/`, data)
}
function remove(id) {
  return Client.delete(`${resource}/${id}/`)
}

const api = {
  getSeatingChartLayout,
  getSeatingChartByRoom,
  get,
  create,
  update,
  remove
}
export default api
