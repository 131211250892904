import Client from "../client/Client"
import version from "../version"
const resource = version + "/tenant/schools"

function gets(params) {
  return Client.get(`${resource}/`, { params })
}
function get(id) {
  return Client.get(`${resource}/${id}/`)
}
function create(data) {
  return Client.post(`${resource}/`, data)
}
function update(id, data) {
  return Client.put(`${resource}/${id}/`, data)
}
function remove(id) {
  return Client.delete(`${resource}/${id}/`)
}
function exportList(params) {
  return Client.post(`${resource}/export-csv/`, { params })
}

function getRooms(school_id, params) {
  return Client.get(`${resource}/${school_id}/rooms/`, { params })
}
function getRoom(school_id, id) {
  return Client.get(`${resource}/${school_id}/rooms/${id}/`)
}
function createRoom(school_id, data) {
  return Client.post(`${resource}/${school_id}/rooms/`, data)
}
function updateRoom(school_id, id, data) {
  return Client.put(`${resource}/${school_id}/rooms/${id}/`, data)
}
function removeRoom(school_id, id) {
  return Client.delete(`${resource}/${school_id}/rooms/${id}/`)
}
function getLessons(school_id, params) {
  return Client.get(`${version}/tenant/lessons/all/`, { params })
}
function getLessonSchedule(school_id) {
  return Client.get(`${resource}/${school_id}/time-tables/`)
}
function createLesson(school_id, data) {
  return Client.post(`${resource}/${school_id}/time-tables/`, data)
}
function updateLesson(school_id, id, data) {
  return Client.put(`${resource}/${school_id}/time-tables/${id}/`, data)
}
function removeLesson(school_id, id) {
  return Client.delete(`${resource}/${school_id}/time-tables/${id}/`)
}
function schoolScheduleAll(school_id, params) {
  return Client.get(`${resource}/${school_id}/schedules/all/`, { params })
}
function schoolScheduleInit(school_id, data) {
  return Client.post(`${resource}/${school_id}/schedules/init/`, data)
}
function schoolScheduleCreate(school_id, data) {
  return Client.post(`${resource}/${school_id}/schedules/create/`, data)
}
function schoolScheduleRemove(school_id, monthly_schedule_id) {
  return Client.delete(`${resource}/${school_id}/schedules/${monthly_schedule_id}/`)
}
function updateBasicSchedule(school_id, data) {
  return Client.put(`${resource}/${school_id}/weekly-schedule/`, data)
}
function getBasicSchedule(school_id) {
  return Client.get(`${resource}/${school_id}/weekly-schedule/`)
}

const api = {
  gets,
  get,
  create,
  update,
  remove,
  exportList,
  //Room
  getRooms,
  getRoom,
  createRoom,
  updateRoom,
  removeRoom,
  //Lesson
  getLessons,
  getLessonSchedule,
  createLesson,
  updateLesson,
  removeLesson,
  schoolScheduleAll,
  schoolScheduleInit,
  schoolScheduleCreate,
  schoolScheduleRemove,
  getBasicSchedule,
  updateBasicSchedule
}
export default api
