import { isValidPhoneNumber } from "react-phone-number-input"
import { isIP, isIPv4 } from "is-ip"

export function isEmailValid(value) {
  if (/^[a-z0-9]+(?:[._+-][a-z0-9]+)*@[a-z0-9-]+(?:\.[a-z0-9-]+)*\.[a-z]+$/i.test(value)) {
    return false
  }
  return true
}

export function isNumeric(str) {
  if (typeof str != "string") return false // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ) // ...and ensure strings of whitespace fail
}

export function isPrice(value) {
  const numberPart = value.toString().split(".")[0]
  if (numberPart && numberPart.length > 10) {
    return false
  }
  return true
}

export function isDecimal(value) {
  const decimalPart = value.toString().split(".")[1]
  if (decimalPart && decimalPart.length > 2) {
    return false
  }
  return true
}

export function isPhoneValid(phone) {
  if (phone && isValidPhoneNumber(phone)) {
    return true // Valid phone number
  }
  return false // Invalid phone number
}

export function isPostcodeValid(value) {
  var regPostcodeUS = /^\d{5}(-\d{4})?$/
  var regexPostcodeUK = /^[A-Z]{1,2}\d[A-Z\d]? \d[A-Z]{2}$/i
  var regexPostcodeJP = /^\d{3}-\d{4}$/
  var regexPostcodeVN = /^\d{6}$/
  if (
    regPostcodeUS.test(value) ||
    regexPostcodeUK.test(value) ||
    regexPostcodeJP.test(value) ||
    regexPostcodeVN.test(value)
  ) {
    return true
  }
  return false
}

export function hasWhiteSpace(s) {
  return s.indexOf(" ") >= 0
}
export function onlyWhiteSpace(s) {
  return s.trim().length === 0
}

export function isValidHttpUrl(string) {
  const regex = /[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/
  return regex.test(string)
}

export function isValidateIP(ip) {
  return isIP(ip) || isIPv4(ip) // Valid if it's a valid IP address or IPv4 address
}

export function isValidateIPRange(from, to) {
  const ipToNumber = (ip) =>
    ip.split(".").reduce((acc, octet, index) => acc + parseInt(octet) * Math.pow(256, 3 - index), 0)

  const fromIPNumber = ipToNumber(from)
  const toIPNumber = ipToNumber(to)

  return toIPNumber >= fromIPNumber
}

export function isSpecialCharacterValid(value) {
  // eslint-disable-next-line no-useless-escape
  if (/^[!@#$%^&*()_+]+$/.test(value)) {
    return true
  }
  return false
}

export function isOnlyNumbers(string) {
  const regex = /^\d+$/
  return regex.test(string)
}
