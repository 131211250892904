import { Button, ConfigProvider } from "antd"
import { hexToRgba } from "lib/function"

export default function ButtonSecondary({ name, children, ...rest }) {
  return (
    <div>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              // controlHeight: 36,
              colorPrimary: `#E3F1F8`,
              defaultColor: `#4DABD1`,
              defaultBg: `#E3F1F8`,
              defaultHoverBg: hexToRgba("#E3F1F8", 0.6),
              defaultActiveBg: "#9fd6f2",
              lineWidth: 0
            }
          }
        }}
      >
        <Button
          style={{
            display: "flex",
            alignItems: "center",
            fontWeight: 700,
            borderRadius: "8px",
            height: "auto",
            padding: "8px 20px",
            fontFamily: "Lato",
            lineHeight: "24px"
          }}
          {...rest}
        >
          {children}
        </Button>
      </ConfigProvider>
    </div>
  )
}
